// extracted by mini-css-extract-plugin
export var anchorLink = "Header-module--anchorLink--f66f5";
export var desktop = "Header-module--desktop--79ae0";
export var fileIcon = "Header-module--fileIcon--ebaae";
export var header = "Header-module--header--1cd5f";
export var headerSmall = "Header-module--headerSmall--8e234";
export var mainMenu = "Header-module--mainMenu--de6eb";
export var mobile = "Header-module--mobile--e0511";
export var socialMenu = "Header-module--socialMenu--2fe76";
export var socialMenuVertical = "Header-module--socialMenuVertical--d2ad2";
export var title = "Header-module--title--a12ab";